<template>
    <div>
	<v-card class="mt-3">
		<v-dialog v-model="dialog.status" persistent>
			<CardDialog :dialog="dialog" />
		</v-dialog>
		<v-row class="px-lg-0 mb-n7">
			<v-col class="mb-7">
				<v-card-title class="py-1">Liberações de clientes</v-card-title>
			</v-col>
		</v-row>
		<v-divider />
		<v-progress-linear indeterminate absolute :active="carregando" />
		<v-card-text>
            <v-alert
                text
                color="primary"
                icon="mdi-information"
                class="ma-0 pt-5 pb-0"
            >
                <p style="font-size: 0.8em" class="mt-n2">
                    Esse recurso tem o objetivo de liberar vendas para clientes que contenha cimento em aguardando com o preço de promoção.<br>
                    Após a inclusão do cliente, o vendedor tera 30 minutos para fechar a venda.
                </p>
            </v-alert>
		</v-card-text>
		<v-divider/>
		<v-card-text>
			<v-row>
				<v-col cols="12" md="9" sm="7" xs="9">
                <v-autocomplete
                    label="Código do Cliente"
                    no-filter
                    v-model="idcliente"
                    outlined
                    dense
                    :search-input.sync="searchCliente"
                    :items="itemsCliente"
                    item-text="nome"
                    item-value="idcliente"
                    class="mb-n10"
                ></v-autocomplete>
				</v-col>
				<v-col cols="12" md="3" sm="5" xs="1">
					<v-btn
						class="py-5"
						width="100%"
						:disabled="carregando"
						color="primary"
						elevation="0"
						@click="liberarCliente()"
					>
					<v-icon class="pr-0 pr-md-2">mdi-account-clock</v-icon>
					<span>Liberar Cliente</span>
				</v-btn>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
    <v-card class="mt-8">
        <v-row class="px-lg-0 mb-n7">
            <v-col class="mb-7">
                <v-card-title class="py-1">Clientes liberados</v-card-title>
            </v-col>
        </v-row>
        <v-divider />
        <v-card-text class="pt-0">
            <v-data-table
                hide-default-footer
                dense
                :headers="[
                    { text: 'Código', value: 'idclifor', sortable: false },
                    { text: 'Cliente', value: 'cliente', sortable: false },
                    { text: 'Data/Hora Inicial', value: 'datainicio', sortable: false },
                    { text: 'Data/Hora Final', value: 'datafim', sortable: false },
                    { text: 'Usuario', value: 'usuarioliberacao', sortable: false },
                ]"
                :items="dados"
                :items-per-page="50"
                class="elevation-0"
            >
                <template v-slot:no-data>
                    <span>Nenhum cliente no momento</span>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
    </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import CardDialog from "../Widgets/CardDialog";

export default {
	name: "FilialDefinirMetas",
	components: { CardDialog },
	data: () => ({
		dialog: { status: false, title: "", msg: "", icon: "", iconColor: "" },
		carregando:false,
		codigoproduto:'',
        idcliente: 0,
        dados:[],
        clientes: [],
        aguardarBusca: "",
        searchCliente: null,
	}),
	computed: {
	...mapState(["backendUrl", "pgLimit", "usuario"]),
        itemsCliente() {
            if (this.clientes == null) return;

            this.clientes.forEach((cliente) => {
                if (cliente.nome.length > this.nomeLimit) {
                    cliente.nome =
                        cliente.nome.slice(0, this.nomeLimit) + "...";
                }
            });

            return this.clientes;
        },
	},
	methods: {
        listarClientesLiberados() {
            this.carregando = true;
            return axios
            .post(`${this.backendUrl}liberacaocimento/listar`, { })
            .then((res) => {
                this.dados = res.data.lista;
                this.carregando = false;
            })
            .catch(() => {

            });
        },

		liberarCliente() {
			return axios
				.post(`${this.backendUrl}liberacaocimento/liberar`, {
					idcliente: this.idcliente
				})
				.then((res) => {
					if (res.data) {
						this.dialog.title = "Cliente Liberado";
						this.dialog.msg = "Cliente liberado por 30 minutos";
						this.dialog.icon = "mdi-check-circle-outline";
						this.dialog.iconColor = "success";
						this.dialog.status = true;
                        this.idcliente = 0;
					} else {
						this.dialog.title = "Erro";
						this.dialog.msg = "Não foi possível liberar cliente!";
						this.dialog.icon = "mdi-alert-circle-outline";
						this.dialog.iconColor = "red";
						this.dialog.status = true;
					}
                    this.listarClientesLiberados();
				})
				.catch(() => {
                    this.dialog.title = "Erro";
                    this.dialog.msg = "Não foi possível liberar cliente!";
                    this.dialog.icon = "mdi-alert-circle-outline";
                    this.dialog.iconColor = "red";
                    this.dialog.status = true;
				});
		},

        carregarClientes(val) {
            clearTimeout(this.aguardarBusca);
            this.aguardarBusca = setTimeout(() => {
                this.carregando = true;
                return axios
                    .post(`${this.backendUrl}cliente/listar`, {
                        limit: 30,
                        offset: 0,
                        busca: val || null,
                    })
                    .then((res) => {
                        this.clientes = res.data.lista;
                        this.carregando = false;
                    })
                    .catch(() => {
                        this.carregando = false;
                    });
            }, this.timeLimit);
        },
        async init() {
            await this.listarClientesLiberados();
        },
	},
    watch: {
        searchCliente: function (val) {
            this.carregarClientes(val);
        },
    },
    created() {
        this.init();
    },
};

</script>

<style scoped>
</style>